<template>
    <!-- 发送量限制》通道发送量限制 -->
    <div id="app_channel_send_num_limit">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    增加
                </el-button>
            </div>
            <div class="head_top_title">通道发送量限制管理</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <!-- 查询条件 -->
            <el-row id="query_condition_id"  class="cursor" style="margin:10px 0px;">
                <el-form  :model="form_data" :inline="true" label-width="50px" style="margin-bottom: 12px;">

                    <el-form-item label="通道">
                        <el-select v-model="form_data.channel_id" filterable remote clearable reserve-keyword placeholder="全部通道" size="mini" style="width:260px" :remote-method="getChannelDataList">
                            <el-option
                                v-for="item in channelDataList"
                                :key="item.id"
                                :label="item.channel_name + (item.id > 0 ?  '('+item.id+')' : '')"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="日期类型" label-width="80px">
                        <el-select v-model="form_data.date_type" size="mini" style="width:80px">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="每日" value="0"></el-option>
                            <el-option label="每月" value="1"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="签名" label-width="50px">
                        <el-input v-model="form_data.signword" placeholder="默认全部" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="getData()" size="mini">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-row>

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-pagination_height-query_condition_height)+'px')}">
                
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini" >

                    <el-table-column  label="通道"  min-width="160" align="center">
                        <template slot-scope="scope">
                            {{scope.row.channel_name+"("+scope.row.channel_id+")"}}
                        </template>
                    </el-table-column>

                    <el-table-column  label="日期类型"  min-width="160" align="center">
                        <template slot-scope="scope">
                            {{scope.row.date_type == 0 ? "每日" : "每月"}}
                        </template>
                    </el-table-column>

                    <el-table-column  label="签名"  min-width="160" align="center">
                        <template slot-scope="scope">
                            {{scope.row.sign_type == 0 ? "不限制签名" : scope.row.signword}}
                        </template>
                    </el-table-column>

                    <el-table-column  label="限制类型"  min-width="160" align="center">
                        <template slot-scope="scope">
                            {{scope.row.control_type == 0 ? "最大提交量" : "最大成功量"}}
                        </template>
                    </el-table-column>

                    <el-table-column  prop="num" label="限制条数" min-width="160"  align="center"> </el-table-column>
                    <el-table-column  prop="updatetime" label="时间" min-width="160"  align="center"> </el-table-column>

                    <el-table-column   label="操作"  min-width="100" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini"  @click="upd(scope.row)">修改</el-button>
                            <el-button type="text" size="mini" style="color:#f56c6c"  @click="del(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>

                </el-table>
            
            </el-row>
            <el-row id="pagination_id" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10,15, 20, 30, 40]"
                     :page-size="pageSize"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>

            
            

        </el-row>

        <!-- 增加组件 -->
        <channelSendNumLimitAddUpd :prop_change_i="prop_add_i" :prop_item="prop_add_upd_item"
            @result="addResult"
        ></channelSendNumLimitAddUpd>

    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import channelSendNumLimitAddUpd from './channel_send_num_limit_add_upd.vue';//组件 通道公司增加或修改

//import axios from "axios";
export default {
    components: {
        channelSendNumLimitAddUpd
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度


            channelDataList:[],//通道列表数据-请求接口获取
            form_data:{
                date_type:"",//类型
                channel_id:"",//通道ID
                signword:"",//签名
            },


            indexPage:1,//分页 第几页
            pageSize:10,//分页 一页的数量值
            tableData:[],//数据
            tableData_total:0,//表格数据总量
            pagination_height:40,//分页的高度
            query_condition_height:75,//查询条件的高度

            //增加或修改
            prop_add_i:0,//增加  组件 弹出框是否可见
            prop_add_upd_item:{},//增加或修改  组件 参数
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.searchID = this.$options.data().searchID;//单个恢复初始化
        // this.indexPage = this.$options.data().indexPage;//单个恢复初始化
        // this.pageSize = this.$options.data().pageSize;//单个恢复初始化
        // this.tableData = this.$options.data().tableData;//单个恢复初始化
        // this.tableData_total = this.$options.data().tableData_total;//单个恢复初始化

        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));

        //初始化设置
        this.initSetUp();

        //获取通道列表数据-请求接口获取
        this.getChannelDataList("");
        
        //查询
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 100);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);


        },

        //查询
        getData(){
            //请求接口
            API.SystemControlServlet({
                param: "channel_control_list",
                indexPage:this.indexPage,
                pageSize:this.pageSize,
                channel_id:this.form_data.channel_id,
                date_type:this.form_data.date_type,
                signword:this.form_data.signword,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;
                    this.tableData_total = res.totalNum;

                    this.$nextTick(function(){
                        //获取分页的高度
                        this.pagination_height = document.getElementById('pagination_id').clientHeight;
                        this.query_condition_height = document.getElementById('query_condition_id').clientHeight;
                        console.log("pagination_height:"+this.pagination_height+" query_condition_height:"+this.query_condition_height);
                    });
                } 
            });
        },

        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.getData();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.getData();
        },

        
        //增加
        add(){
            console.log("增加");
            this.prop_add_upd_item.prop_type = "add";
            this.prop_add_i ++;//增加或修改  组件 弹出框是否可见
        },
        //修改
        upd(item){
            console.log("修改");
             this.prop_add_upd_item = item;
            this.prop_add_upd_item.prop_type = "upd";
            this.prop_add_i ++;//增加或修改  组件 弹出框是否可见
        },
        //通道增加组件 结果回调方法
        addResult(){
            //查询-- 请求接口
            this.getData();
        },
        //删除
        del(id){
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    //请求接口
                    API.SystemControlServlet({
                        param: "del_channel_control",
                        id:id,
                    }).then((res) => {
                        if (res.recode === 0) {
                            this.$message({showClose: true,message: '信息删除成功', type: 'success'});
                            //重新加载数据
                            this.getData();
                        }
                    });
            }).catch(() => {//已取消删除
            });
        },

        //获取通道列表数据-请求接口获取
        getChannelDataList(search){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:search,//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelDataList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                }
            });
        },
        
        //返回
        goBack() {
            API.router_to("/index_system_control");
        }
    }
};

</script>

<style scoped>

</style>